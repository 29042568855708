import LinkWrapper from 'UI/components/LinkWrapper';
import styles from './styles.module.scss';

const WhatsAppChat = () => (
  <LinkWrapper
    path="http://wa.me/19406016017"
    className={styles.link}
    isSocialLink
  >
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.4001 6.54664C24.1775 5.31211 22.7214 4.33326 21.1167 3.66712C19.5121 3.00098 17.7908 2.66088 16.0534 2.66664C8.7734 2.66664 2.84007 8.59997 2.84007 15.88C2.84007 18.2133 3.4534 20.48 4.60007 22.48L2.7334 29.3333L9.7334 27.4933C11.6667 28.5466 13.8401 29.1066 16.0534 29.1066C23.3334 29.1066 29.2667 23.1733 29.2667 15.8933C29.2667 12.36 27.8934 9.03997 25.4001 6.54664ZM16.0534 26.8666C14.0801 26.8666 12.1467 26.3333 10.4534 25.3333L10.0534 25.0933L5.8934 26.1866L7.00006 22.1333L6.7334 21.72C5.6368 19.9694 5.05463 17.9457 5.0534 15.88C5.0534 9.82664 9.98673 4.8933 16.0401 4.8933C18.9734 4.8933 21.7334 6.03997 23.8001 8.11997C24.8236 9.13845 25.6346 10.35 26.1862 11.6844C26.7378 13.0188 27.019 14.4494 27.0134 15.8933C27.0401 21.9466 22.1067 26.8666 16.0534 26.8666ZM22.0801 18.6533C21.7467 18.4933 20.1201 17.6933 19.8267 17.5733C19.5201 17.4666 19.3067 17.4133 19.0801 17.7333C18.8534 18.0666 18.2267 18.8133 18.0401 19.0266C17.8534 19.2533 17.6534 19.28 17.3201 19.1066C16.9867 18.9466 15.9201 18.5866 14.6667 17.4666C13.6801 16.5866 13.0267 15.5066 12.8267 15.1733C12.6401 14.84 12.8001 14.6666 12.9734 14.4933C13.1201 14.3466 13.3067 14.1066 13.4667 13.92C13.6267 13.7333 13.6934 13.5866 13.8001 13.3733C13.9067 13.1466 13.8534 12.96 13.7734 12.8C13.6934 12.64 13.0267 11.0133 12.7601 10.3466C12.4934 9.70664 12.2134 9.78664 12.0134 9.7733H11.3734C11.1467 9.7733 10.8001 9.8533 10.4934 10.1866C10.2001 10.52 9.34673 11.32 9.34673 12.9466C9.34673 14.5733 10.5334 16.1466 10.6934 16.36C10.8534 16.5866 13.0267 19.92 16.3334 21.3466C17.1201 21.6933 17.7334 21.8933 18.2134 22.04C19.0001 22.2933 19.7201 22.2533 20.2934 22.1733C20.9334 22.08 22.2534 21.3733 22.5201 20.6C22.8001 19.8266 22.8001 19.1733 22.7067 19.0266C22.6134 18.88 22.4134 18.8133 22.0801 18.6533Z"
        fill="#FFFFFF"
      />
    </svg>
  </LinkWrapper>
);

export default WhatsAppChat;
